import React from 'react'
import PropTypes from 'prop-types'

import Navigation from '../components/navigation'
import Footer from '../components/footer'

import '../scss/bootstrap.scss'
import '../scss/custom.scss'

const Layout = ({ children }) => (
  <div>
    <Navigation />
    <main role="main">{children}</main>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
