import React from "react";

const LinkedinIcon = () => (
     <svg className="linkedin-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="transparent" viewBox="0 0 16 16" aria-labelledby="title">
	    <title id="title">Linkedin Icon</title>
      <path d="M15.3 0H.7C.3 0 0 .3 0 .7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V.7c-.1-.4-.4-.7-.8-.7zM4.7 13.6H2.4V6h2.4v7.6h-.1zM3.6 5c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4V9.9c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8H6.2V6h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" fill="#fff"/>
      </svg>
);

export default LinkedinIcon;
