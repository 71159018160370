import React from "react";

const ForerunnerLogo = () => (
     <svg className="forerunner-logo" xmlns="http://www.w3.org/2000/svg" width="130" height="16" fill="transparent" viewBox="0 0 172 16" aria-labelledby="title">
	    <title id="title">Forerunner Logo</title>
        <path d="M2.22 15.625V8.803h8.188V6.716H2.221V3.157l.722-.695h8.642V.375H0v15.25h2.22zM29.947 8c0-4.495-2.676-8-6.903-8-4.201 0-6.877 3.505-6.877 8 0 4.468 2.676 8 6.877 8 4.227 0 6.903-3.532 6.903-8zm-2.355 0c0 3.238-1.525 5.86-4.548 5.86-3.024 0-4.522-2.622-4.522-5.86 0-3.237 1.498-5.886 4.522-5.886 3.023 0 4.548 2.649 4.548 5.886zM37.392 9.552h3.344l4.361 6.073H47.8l-4.2-5.779v-.562c2.354-.562 3.611-2.033 3.611-4.307 0-2.97-2.167-4.602-6.1-4.602h-5.967v15.25h2.248V9.552zm3.665-7.117c2.622 0 3.853.91 3.853 2.542 0 1.578-1.23 2.488-3.853 2.488h-3.665V3.157l.722-.722h2.943zM55.298 12.816V8.803h8.107V6.716h-8.107V3.157l.696-.695h8.589V.375H53.05v15.25h11.8V13.54h-8.857l-.696-.723zM72.937 9.552h3.345l4.361 6.073h2.702l-4.2-5.779v-.562c2.354-.562 3.612-2.033 3.612-4.307 0-2.97-2.168-4.602-6.1-4.602H70.69v15.25h2.247V9.552zm3.666-7.117c2.622 0 3.853.91 3.853 2.542 0 1.578-1.231 2.488-3.853 2.488h-3.666V3.157l.723-.722h2.943zM94.135 13.886c-2.167 0-3.826-1.552-3.826-3.986V.375H88.06v9.819c0 3.505 2.703 5.806 6.074 5.806s6.073-2.301 6.073-5.806V.374h-2.247V9.9c0 2.434-1.659 3.986-3.826 3.986zM118.008 15.625V.375h-2.114v11.023h-.695L108.911.375h-3.104v15.25h2.114V3.826h.696l6.796 11.8h2.595zM135.781 15.625V.375h-2.114v11.023h-.696L126.684.375h-3.104v15.25h2.114V3.826h.695l6.796 11.8h2.596zM144.162 12.816V8.803h8.107V6.716h-8.107V3.157l.696-.695h8.588V.375h-11.531v15.25h11.799V13.54h-8.856l-.696-.723zM161.801 9.552h3.345l4.361 6.073h2.702l-4.2-5.779v-.562c2.354-.562 3.612-2.033 3.612-4.307 0-2.97-2.168-4.602-6.101-4.602h-5.966v15.25h2.247V9.552zm3.666-7.117c2.622 0 3.853.91 3.853 2.542 0 1.578-1.231 2.488-3.853 2.488h-3.666V3.157l.723-.722h2.943z" fill="#2A70FF"/>
      </svg>
);

export default ForerunnerLogo;
