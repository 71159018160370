import React from "react";
import { Container } from "reactstrap";

import ForerunnerLogo from "../images/icons/ForerunnerLogo";

import "./__styles__/navigation.module.scss";

const Navigation = () => {
  return (
    <header styleName="header" role="banner">
      <Container>
        <nav styleName="nav">
          <a
            href="https://withforerunner.com"
            styleName="logo"
            aria-label="homepage link"
          >
            <ForerunnerLogo />
          </a>
          <a
            styleName="demo-button"
            href="https://www.withforerunner.com/contact"
          >
            Request a Demo
          </a>
        </nav>
      </Container>
    </header>
  );
};

export default Navigation;
