import React from "react";

const FacebookIcon = () => (
     <svg className="facebook-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="transparent" viewBox="0 0 16 16" aria-labelledby="title">
	    <title id="title">Facebook Icon</title>
      <path d="M15.3 0H.7C.3 0 0 .3 0 .7v14.7c0 .3.3.6.7.6H8v-5H6V8h2V6c0-2.1 1.2-3 3-3h2v3h-1c-.6 0-1 .4-1 1v1h2.6l-.6 3h-2v5h4.3c.4 0 .7-.3.7-.7V.7c0-.4-.3-.7-.7-.7z" fill="#fff"/>
      </svg>
);

export default FacebookIcon;
