import React from "react";
import { Container, Row, Col } from "reactstrap";

import ForerunnerLogo from "../images/icons/ForerunnerLogo";
import TwitterIcon from "../images/icons/TwitterIcon";
import FacebookIcon from "../images/icons/FacebookIcon";
import LinkedinIcon from "../images/icons/LinkedinIcon";

const Footer = () => (
  <footer className="footer-container">
    <Container>
      <Row className="footer-links">
        <Col className="col-12 col-md-9">
          <ul>
            <li>
              <a href="https://withforerunner.com">
                <ForerunnerLogo />
              </a>
            </li>
          </ul>
        </Col>
        <Col className="col-12 col-md-3 footer-icons">
          <a
            href="https://twitter.com/ForerunnerFlood"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://www.linkedin.com/company/forerunner-flood/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedinIcon />
          </a>
          <a
            href="https://www.facebook.com/Forerunner-Flood-2164446263819183/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
        </Col>
      </Row>
      <Row className="footer-sub">
        <Col>
          <p className="copyright text-center">
            Copyright © Forerunner Industries, Inc. 2019. All rights reserved.
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
